import v1 from "./v1.webm"
import v2 from "./v2.webm"
import v4 from "./v4.webm"
import v3 from "./v3.webm"
import v1Alt from "./v1.mp4"
import v2Alt from "./v2.mp4"
import v4Alt from "./v4.mp4"
import v3Alt from "./v3.mp4"
import p1 from "./p1.webp"
import p2 from "./p2.webp"
import p3 from "./p3.webp"

export const gdsLandingPage = {
  name: "GDS Landing Page",
  meta: "2020",
  desc: "A landing page for Singapore’s Government Digital Services.\nBuilt using TypeScript, Gatsby, styled-components.",
  link: "https://hive.tech.gov.sg",
  pictures: [
    {
      vidSrc: v1,
      vidSrcType: "video/webm",
      vidSrcAlt: v1Alt,
      vidSrcAltType: "video/mp4",
      caption: "Animated hero banner (Desktop)",
      marginTop: 80,
      gridColumn: "1 / -1",
      autoPlay: true,
      heightWidthPercentage: 51.2,
    },
    {
      vidSrc: v2,
      vidSrcType: "video/webm",
      vidSrcAlt: v2Alt,
      vidSrcAltType: "video/mp4",
      caption: "Animated hero banner (Mobile)",
      marginTop: 40,
      autoPlay: true,
      heightWidthPercentage: 200,
    },
    {
      vidSrc: v4,
      vidSrcType: "video/webm",
      vidSrcAlt: v4Alt,
      vidSrcAltType: "video/mp4",
      caption: "Animated 'What we do' section (Mobile)",
      marginTop: 240,
      gridColumn: "3 / span 1",
      gridColumnAlt: "2 / span 1",
      autoPlay: true,
      heightWidthPercentage: 200,
    },
    {
      vidSrc: v3,
      vidSrcType: "video/webm",
      vidSrcAlt: v3Alt,
      vidSrcAltType: "video/mp4",
      caption: "Animated 'What we do' section (Desktop)",
      marginTop: 40,
      gridColumn: "1 / -1",
      autoPlay: true,
      heightWidthPercentage: 51.2,
    },
    {
      src: p1,
      caption: "'How we work' section",
      marginTop: 40,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / -1",
      heightWidthPercentage: 62.3,
    },
    {
      src: p2,
      caption: "'Benefits' section",
      marginTop: 40,
      gridColumn: "1 / span 2",
      gridColumnAlt: "1 / -1",
      heightWidthPercentage: 62.3,
    },
    {
      src: p3,
      caption: "'Blog' section",
      marginTop: 40,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / -1",
      heightWidthPercentage: 62.3,
    },
  ],
  isMainProject: true,
}