import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import About from './components/About'
import ProjectsList from './components/ProjectsList'
import Contact from './components/Contact'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from './theme'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import UpdateBanner from './components/UpdateBanner'

const GlobalStyle = createGlobalStyle`
  :root {
    --text-primary: #333;
    --text-secondary: #757575;
    --background: white;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --text-primary: hsl(24deg 6% 70%);
      --text-secondary: hsl(24deg 6% 55%);
      --background: hsl(210deg 11% 8%);
    }
  }
  
  * {
    box-sizing: border-box;
  }

  html {
    background-color: black;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica,
      Arial, sans-serif;
    text-rendering: optimizeLegibility;
    color: var(--text-primary);
    background-color: black;
  }
`

const sw = {
  // Called from outside React once the service worker finds that there's an update.
  setHasUpdate: () => null,

  // Variable to hold the registration object that is subsequently used in
  // reload to post messages and listen on events.
  registration: null,

  // Called within a React component to trigger the activation of the 
  // latest service worker.
  reload: function() {
    // reference: https://github.com/gglukmann/cra-sw/blob/master/src/App.js
    const registrationWaiting = this.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          // reload only when the new service worker has been activated
          window.location.reload();
        }
      });
    }
  }
}


const App = () => {
  const [hasUpdate, setHasUpdate] = useState(false);
  sw.setHasUpdate = setHasUpdate;
  
  return (
    <ThemeProvider theme={theme}>
      <div>
        <GlobalStyle />
        {hasUpdate && (
          <UpdateBanner onClickUpdate={() => sw.reload()} />
        )}
        <About />
        <ProjectsList />
        <Contact />
      </div>
    </ThemeProvider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    sw.registration = registration;
    sw.setHasUpdate(true);
  }
});
