// Note that this isn't being used in code as I've opted to use CSS vars instead.
// However, am leaving this here for future reference if more customisation is required.
const theme = {
  colors: {
    textPrimary: '#333',
    textSecondary: '#757575',
    background: 'white'
  }
}

export default theme