import React, { useState } from 'react'
import styled from 'styled-components'
import Project from './Project'
import { projectData } from '../projects';
import { DownButton } from './DownButton';

const Wrapper = styled.div`
  background-color: var(--background);
  margin-top: 100vh;
`;

const List = styled.div`
  max-width: 2000px;
  margin: 0 auto;

  > * {
    padding-bottom: 200px;
  }
`

const DownButtonPositioner = styled.div`
  padding-top: 20vh;
  padding-bottom: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProjectsList = () => {
  const [shouldSeeMore, setShouldSeeMore] = useState(false);
  
  const mainProjects = projectData
    .filter(({ isMainProject }) => isMainProject)
    .map(p => <Project key={p.name} project={p}/>)
  const additionalProjects = projectData
    .filter(({ isMainProject }) => !isMainProject)
    .map(p => <Project key={p.name} project={p}/>)

  return (
    <Wrapper>
      <List>
        {mainProjects}
      </List>
      {!shouldSeeMore && (
        <DownButtonPositioner>
        <DownButton onClick={() => setShouldSeeMore(true)}>Click to see more projects</DownButton>
        </DownButtonPositioner>
      )}
      {shouldSeeMore && (
        <List>
          {additionalProjects}
        </List>
      )}
    </Wrapper>
  )
}

export default ProjectsList
