import React from 'react'
import styled from 'styled-components'
import { Link } from './Link'
import profilePic from "../assets/profile-pic.jpg"

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  bottom: 0;
`

const Header = styled.h1`
  font-size: 3em;
  margin: 0 0 0.8em;
  font-weight: 200;
`

const Picture = styled.img`
  border-radius: 50%;
  max-width: 300px;
  width: 100%;
  aspect-ratio: 1 / 1;
`

const FooterLink = styled(Link)`
  color: white;
  margin-top: 0.75em;

  :first-of-type {
    margin-top: 1.5em;
  }
`

const Contact = () => {
  return (
    <Wrapper>
      <Header>Say hi!</Header>
      <Picture src={profilePic} alt="Profile picture" />
      <FooterLink href="mailto:hello@sebastianquek.com" target="_blank">Email</FooterLink>
      <FooterLink href="https://github.com/sebastianquek" target="_blank">GitHub</FooterLink>
      <FooterLink href="https://www.linkedin.com/in/sebastian-quek" target="_blank">LinkedIn</FooterLink>
      <FooterLink href="https://t.me/sebastianquek" target="_blank">Telegram</FooterLink>
    </Wrapper>
  )
}

export default Contact
