import v1 from "./v1.webm";
import v1Alt from "./v1.mp4";
import p1 from "./p1.webp";
import p2 from "./p2.webp";
import p3 from "./p3.webp";
import p4 from "./p4.webp";
import p5 from "./p5.webp";
import p6 from "./p6.webp";

export const mapyourgoals = {
  name: "mapyourgoals",
  meta: "2019",
  desc: "A calculator that helps you understand how much you should save every month to reach your financial goals.\nMove and resize your goals through the interactive D3.js chart to see the effects and recommendations.\nBuilt using React, Redux, D3.js.",
  link: "https://fpds-myg.netlify.app/goals",
  pictures: [
    {
      vidSrc: v1,
      vidSrcType: "video/webm",
      vidSrcAlt: v1Alt,
      vidSrcAltType: "video/mp4",
      caption:
        "Interactive chart to find out if you're saving enough for your goals",
      marginTop: 200,
      marginTopAlt: 40,
      gridColumn: "1 / -1",
      autoPlay: true,
      heightWidthPercentage: 57.5,
    },
    {
      src: p1,
      caption: "Home page",
      marginTop: 40,
      heightWidthPercentage: 200,
    },
    {
      src: p2,
      caption: "Enter current savings",
      marginTop: 200,
      heightWidthPercentage: 200,
    },
    {
      src: p3,
      caption: "Select goal category",
      marginTop: 120,
      heightWidthPercentage: 200,
    },
    {
      src: p4,
      caption: "Enter goal details (amount and date)",
      marginTop: 60,
      heightWidthPercentage: 200,
    },
    {
      src: p5,
      caption: "See recommendations on how to meet your goals",
      marginTop: 140,
      heightWidthPercentage: 200,
    },
    {
      src: p6,
      caption: "Commit to saving the specified amount",
      marginTop: 220,
      heightWidthPercentage: 200,
    },
  ],
  isMainProject: true,
};
