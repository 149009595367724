import { keyframes } from 'styled-components'

export const fadeSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 2em);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
