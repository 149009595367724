import React from 'react'
import PropTypes from 'prop-types'
import ProjectMediaItem from './ProjectMediaItem'
import styled from 'styled-components'

const Grid = styled.div`
  grid-area: pictures;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;

  > *:last-child {
    justify-self: center;
    align-self: start;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ProjectMedia = ({pictures}) => {
  const picNodes = pictures.map((pic, idx) => {
    return <ProjectMediaItem key={pic.caption} {...pic} />
  })
  return (
    <Grid>
      {picNodes}
    </Grid>
  )
}

ProjectMedia.propTypes = {
  pictures: PropTypes.array
}

export default ProjectMedia
