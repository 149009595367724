import React from "react";
import styled from "styled-components";
import { fadeSlideUp, fadeIn } from "../animation";
import { DownButton } from "./DownButton";
import { Link } from "./Link";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: -1;
  top: 0;
`;

const InnerWrapper = styled.div`
  width: 50vw;
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1825px) {
    width: 60%;
  }

  @media (max-width: 1530px) {
    width: 70%;
  }

  @media (max-width: 770px) {
    width: 94%;
  }
`;

const Paragraph = styled.p`
  font-size: 3em;
  font-weight: 200;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  animation: ${fadeIn} 0.7s;

  @media (max-width: 1530px) {
    font-size: 2.5em;
  }

  @media (max-width: 1200px) {
    font-size: 2em;
  }

  @media (max-width: 550px) {
    font-size: 1.6em;
  }

  @media (max-width: 450px) {
    font-size: 1.4em;
  }
`;

const Header = styled.h1`
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background-color: black;
  animation: ${fadeSlideUp} 0.5s;

  @media (max-width: 1200px) {
    font-size: 1.1em;
  }

  @media (max-width: 550px) {
    font-size: 0.8em;
  }
`;

function getBgImage(option) {
  if (option === 0) {
    return "background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);";
  } else if (option === 1) {
    return "background-image: linear-gradient(64deg, #08AEEA 0%, #2AF598 100%);";
  } else if (option === 2) {
    return "background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);";
  }
}

const DownButtonPositioner = styled.div`
  position: absolute;
  bottom: 10vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const Highlight = styled.span`
  display: inline-block;
  position: relative;
  color: white;
  background-color: black;
  cursor: text;

  ::before {
    content: "";
    display: inline-block;
    width: calc(100% - 1px);
    height: 90%;
    top: 5%;
    left: 1px;
    position: absolute;
    transition: 0.6s;
    mix-blend-mode: multiply;
    opacity: 0;
    ${({ option }) => getBgImage(option)}
  }

  &.highlighted::before {
    transition: 2s;
  }

  &.highlighted,
  ${Paragraph}:hover &,
  ${DownButton}:hover ~ & {
    mix-blend-mode: lighten;
  }

  &.highlighted::before,
  ${Paragraph}:hover &::before,
  ${DownButton}:hover ~ ${Paragraph} &::before {
    top: 9%;
    opacity: 1;
    ${({ option }) => getBgImage(option)}
  }
`;

const ContactLinks = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1.3em;
  font-size: 1em;
  animation: ${fadeSlideUp} 1s;
  color: var(--text-secondary);

  @media (max-width: 1200px) {
    font-size: 0.9em;
  }

  @media (max-width: 770px) {
    font-size: 0.8em;
  }

  @media (max-width: 550px) {
    font-size: 0.6em;
  }
`;

const ContactLink = styled.li``;

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlighted: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ highlighted: true }), 2000);
  }

  render() {
    const className = `${this.state.highlighted ? "highlighted" : ""}`;
    return (
      <Wrapper ref={(e) => (this.e = e)}>
        <InnerWrapper>
          <Header>Sebastian Quek</Header>
          <Paragraph>
            I’m a software engineer who loves
            <br />
            <Highlight className={className} option={0}>
              crafting seamless user experiences
            </Highlight>
            ,<br />
            <Highlight className={className} option={1}>
              designing beautiful user interfaces
            </Highlight>{" "}
            and
            <br />
            <Highlight className={className} option={2}>
              ultimately developing them
            </Highlight>
            .
          </Paragraph>
          <ContactLinks>
            <ContactLink>
              <Link href="mailto:hello@sebastianquek.com" target="_blank">
                Email
              </Link>
            </ContactLink>
            <ContactLink>
              <Link href="https://github.com/sebastianquek" target="_blank">
                GitHub
              </Link>
            </ContactLink>
            <ContactLink>
              <Link
                href="https://www.linkedin.com/in/sebastian-quek"
                target="_blank"
              >
                LinkedIn
              </Link>
            </ContactLink>
            <ContactLink>
              <Link href="https://t.me/sebastianquek" target="_blank">
                Telegram
              </Link>
            </ContactLink>
          </ContactLinks>
          <DownButtonPositioner>
            <DownButton
              onClick={() => {
                window.scrollTo(0, this.e.getBoundingClientRect().bottom);
              }}
            >
              check out my work
            </DownButton>
          </DownButtonPositioner>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default About;
