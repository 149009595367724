import p1 from "./1.webp"
import p2 from "./2.webp"
import p3 from "./3.webp"
import p4 from "./4.webp"
import p5 from "./5.webp"
import p6 from "./6.webp"
import p7 from "./7.webp"
import p8 from "./8.webp"
import p9 from "./9.webp"
import banner from "./banner.webp"

export const letterbox = {
  name: "Letterbox",
  meta: "Late 2015",
  desc: "A cross-platform dating app where you pick 5 'Would you rather' questions that others can answer. If you like their answers, you can start chatting with them.\nUnsure where to go on dates? Our budget friendly catalogue helps you find places to explore.\nBuilt using Javascript, Ionic/Cordova.",
  link: "https://github.com/LetterboxDev/frontend",
  pictures: [
    {
      src: p1,
      caption: "Pick 5 'would you rather questions'",
      marginTop: 100,
      marginTopAlt: 0,
      heightWidthPercentage: 163.5,
    },
    {
      src: p2,
      caption: "Discover other people's profiles",
      marginTop: 240,
      marginTopAlt: 200,
      heightWidthPercentage: 163.5,
    },
    {
      src: p3,
      caption: "Send a letter by answering their 5 questions",
      marginTop: 480,
      marginTopAlt: 50,
      heightWidthPercentage: 163.5,
    },
    {
      src: p4,
      caption: "See the responses of people who sent you letters",
      marginTop: -180,
      marginTopAlt: 220,
      heightWidthPercentage: 163.5,
    },
    {
      src: p5,
      caption: "Chat",
      marginTop: 100,
      marginTopAlt: -50,
      heightWidthPercentage: 163.5,
    },
    {
      src: p6,
      caption: "Browse the catalogue to find cutouts (promotions)",
      marginTop: 300,
      marginTopAlt: 100,
      heightWidthPercentage: 163.5,
    },
    {
      src: p7,
      caption: "Find places to go",
      marginTop: 300,
      marginTopAlt: 120,
      heightWidthPercentage: 163.5,
    },
    {
      src: p8,
      caption: "Find places to eat",
      marginTop: 200,
      marginTopAlt: 50,
      heightWidthPercentage: 163.5,
    },
    {
      src: p9,
      caption: "Share cutouts with your potential partner",
      marginTop: 100,
      marginTopAlt: 50,
      heightWidthPercentage: 163.5,
    },
    {
      src: banner,
      caption: "banner",
      marginTop: 50,
      marginTopAlt: 50,
      gridColumn: "1 / -1",
      heightWidthPercentage: 54.2,
    },
  ],
}