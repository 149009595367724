import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ScrollWrapper from './ScrollWrapper'
import { ProjectLink } from './ProjectLink'

const Wrapper = styled.div`
  max-height: 100vh;
  max-width: 500px;
  margin: 3em 0 3.6em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: description;

  transition: 0.8s;
  @media (min-width: 1000px) {
    position: sticky;
    top: 0;
    margin: 0;
  }
`

const Name = styled.h1`
  font-size: 3em;
  margin: 0;
  line-height: 1;
  color: var(--text-primary);

  @media (min-width: 1400px) {
    font-size: 3.8em;
  }
`

const Meta = styled.p`
  font-size: 1.5em;
  font-weight: 200;
  margin-top: 0.4em;
  margin-bottom: 0;
  color: var(--text-secondary);
`

const Description = styled.p`
  margin-top: 1.5em;
  margin-bottom: 0;
  color: var(--text-primary);
  font-size: 1.5em;
  line-height: 1.4;

  + p {
    margin-top: 1.2em;
  }
`

class ProjectDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      yOffset: 3,
      opacity: 0
    }
  }

  render () {
    const desc = this.props.desc.split('\n').map((item, i) => {
      return <Description key={i}>{item}</Description>
    })
    return (
      <Wrapper
        style={{
          opacity: this.state.opacity,
          transform: `translate3d(0, ${this.state.yOffset}em, 0)`,
        }}
      >
        <ScrollWrapper
          onScrollIntoView={() => this.setState({yOffset: 0, opacity: 1})}
          onScrollOutOfViewToBottom={() => this.setState({yOffset: 3, opacity: 0})}
          onScrollOutOfViewToTop={() => this.setState({yOffset: -3, opacity: 0})}>
          <Name>{this.props.name}</Name>
          <Meta>{this.props.meta}</Meta>
          {desc}
          {this.props.link && <ProjectLink title={this.props.name} url={this.props.link}/>}
        </ScrollWrapper>
      </Wrapper>
    )
  }
}

ProjectDetails.propTypes = {
  desc: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.string,
  link: PropTypes.string
}

export default ProjectDetails
