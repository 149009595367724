import p1 from "./1.webp"
import p2 from "./2.webp"
import p3 from "./3.webp"
import p4 from "./4.webp"
import p5 from "./5.webp"
import icon from "./icon.webp"
import logo from "./logo.svg"

export const threemoji = {
  name: "Threemoji",
  meta: "Mid 2015",
  desc: "An anonymous chatting app for Android where you pick 3 emojis that define your identity and get matched with people nearby who chose a similar set of emojis.\nBuilt using Java, Python, GCM, AWS.",
  link: "https://github.com/threemoji/threemoji",
  pictures: [
    {
      src: p1,
      caption: "Simple, anonymous, text messaging",
      marginTop: 80,
      heightWidthPercentage: 177.5,
    },
    {
      src: p2,
      caption: "create your profile using 3 emojis",
      marginTop: 300,
      marginTopAlt: 400,
      heightWidthPercentage: 177.5,
    },
    {
      src: p3,
      caption: "Match up with similar profiles near you",
      marginTop: 500,
      marginTopAlt: -100,
      heightWidthPercentage: 177.5,
    },
    {
      src: p4,
      caption: "See other profiles near you",
      marginTop: 200,
      heightWidthPercentage: 177.5,
    },
    {
      src: p5,
      caption: "Start chatting instantly with anyone",
      marginTop: -80,
      marginTopAlt: 50,
      heightWidthPercentage: 177.5,
    },
    {
      src: icon,
      caption: "icon",
      marginTop: 400,
      marginTopAlt: 200,
      width: 200,
      noShadow: true,
      heightWidthPercentage: 100,
    },
    {
      src: logo,
      caption: "logo",
      width: 500,
      marginTop: 50,
      marginTopAlt: 100,
      gridColumn: "1 / -1",
      noShadow: true,
      heightWidthPercentage: 25,
    },
  ],
}