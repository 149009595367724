import p1 from "./p1.webp"
import p2 from "./p2.webp"
import p3 from "./p3.webp"
import p4 from "./p4.webp"
import v1 from "./v1.webm"
import v1Alt from "./v1.mp4"
import p5 from "./p5.webp"

export const supplyally = {
  name: "SupplyAlly",
  meta: "2020 - 2022",
  desc: "Service that tracks the distribution of items to ensure each resident gets their fair share.\nPrimarily used for COVID-19 initiatives such as the distribution of reusable masks, contact tracing tokens, antigen rapid test kits and tourism credits.\nBuilt using TypeScript, React Native, Expo, Node.js, Serverless Framework, AWS (API Gateway, Lambda, DynamoDB).",
  link: "https://github.com/rationally-app/mobile-application",
  pictures: [
    {
      src: p1,
      caption: "Login via QR codes",
      marginTop: 20,
      heightWidthPercentage: 216.5,
    },
    {
      src: p2,
      caption: "Scan ID (type of ID is configurable)",
      marginTop: 120,
      heightWidthPercentage: 200,
    },
    {
      src: p3,
      caption: "Over-the-air updates via Expo",
      marginTop: 220,
      marginTopAlt: 40,
      heightWidthPercentage: 216.5,
    },
    {
      src: p4,
      caption: "Quota of items to be distributed",
      marginTop: 20,
      marginTopAlt: 500,
      heightWidthPercentage: 216.5,
    },
    {
      vidSrc: v1,
      vidSrcType: "video/webm",
      vidSrcAlt: v1Alt,
      vidSrcAltType: "video/mp4",
      caption: "Toggles with acceleration and rounding",
      marginTop: 80,
      marginTopAlt: -320,
      autoPlay: true,
      heightWidthPercentage: 107,
    },
    {
      src: p5,
      caption: "Configurable items via AWS Parameter Store",
      marginTop: 80,
      gridColumn: "1 / -1",
      heightWidthPercentage: 45.2,
    },
  ],
  isMainProject: true,
}