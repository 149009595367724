import p1 from "./1.webp"
import p2 from "./2.webp"
import p3 from "./3.webp"
import p4 from "./4.webp"
import p5 from "./5.webp"
import p6 from "./6.webp"
import p7 from "./7.webp"
import p8 from "./8.webp"
import p9 from "./9.webp"
import p10 from "./10.webp"
import logo from "./logo.svg"

export const fragments = {
  name: "Fragments",
  meta: "Late 2017",
  desc: "A place-based locative game where you search for fragments of the now-defunct Sungei Road Flea Market.\nDiscover a different side of Sungei Road, a place full of unique activities and fascinating stories, by going on an adventure across the periphery of the old Sungei Road Flea Market.\nMockups created in Figma.",
  pictures: [
    {
      src: p1,
      caption: "Introduction",
      marginTop: 400,
      marginTopAlt: 50,
      heightWidthPercentage: 178.8,
    },
    {
      src: p2,
      caption: "Pick a difficulty",
      marginTop: 50,
      marginTopAlt: 150,
      heightWidthPercentage: 178.8,
    },
    {
      src: p3,
      caption: "Onboarding (1)",
      marginTop: 200,
      marginTopAlt: 160,
      heightWidthPercentage: 178.8,
    },
    {
      src: p4,
      caption: "Onboarding (2)",
      marginTop: 150,
      marginTopAlt: 120,
      heightWidthPercentage: 178.8,
    },
    {
      src: p5,
      caption: "Match a fragment",
      marginTop: -160,
      marginTopAlt: 40,
      heightWidthPercentage: 178.8,
    },
    {
      src: p6,
      caption: "Look for clues (1)",
      marginTop: 300,
      marginTopAlt: 300,
      heightWidthPercentage: 178.8,
    },
    {
      src: p7,
      caption: "Look for clues (2)",
      marginTop: -50,
      marginTopAlt: -60,
      heightWidthPercentage: 178.8,
    },
    {
      src: p8,
      caption: "End of experience (1)",
      marginTop: 300,
      marginTopAlt: 80,
      heightWidthPercentage: 178.8,
    },
    {
      src: p9,
      caption: "End of experience (2)",
      marginTop: 500,
      marginTopAlt: 60,
      heightWidthPercentage: 178.8,
    },
    {
      src: p10,
      caption: "See what others did",
      marginTop: -200,
      marginTopAlt: 200,
      heightWidthPercentage: 178.8,
    },
    {
      src: logo,
      caption: "logo",
      marginTop: 20,
      marginTopAlt: 50,
      gridColumn: "1 / -1",
      width: 500,
      noShadow: true,
      heightWidthPercentage: 52.4,
    },
  ],
}