import { commute } from "./commute";
import { courir } from "./courir";
import { fragments } from "./fragments";
import { gantries } from "./gantries";
import { gdsLandingPage } from "./gds-landing";
import { letterbox } from "./letterbox";
import { mapyourgoals } from "./mapyourgoals";
import { supplyally } from "./supplyally";
import { threemoji } from "./threemoji";

export const projectData = [
  gantries,
  gdsLandingPage,
  supplyally,
  mapyourgoals,
  commute,
  courir,
  letterbox,
  fragments,
  threemoji
]