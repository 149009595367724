import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fadeSlideUp } from '../animation'

const Positioner = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;

  @media (max-width: 700px) {
    width: 100%;
  }
`

const Banner = styled.div`
  padding: 0.5em 1.2em;
  background: hsl(210deg 10% 23%);
  border-radius: 1em 1em 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  color: hsl(24deg 9% 77%);
  animation: ${fadeSlideUp} 0.5s;
`

const Text = styled.p`
  margin: 0;
  font-size: inherit;
  line-height: 1;
  padding-bottom: 0.1em;
`

const Button = styled.button`
  appearance: none;
  border: none;
  font-family: 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;
  padding: 1em;
  cursor: pointer;
  background: none;
  border-radius: 0.5em;
  color: hsl(13deg 91% 73%);

  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255,0.05);
    }
  }
`

const UpdateBanner = ({ onClickUpdate }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <Positioner>
      <Banner>
        <Text>
          Update to see the latest content
        </Text>
        <Button onClick={() => {
          setIsUpdating(true);
          onClickUpdate();
        }}>
          {isUpdating ? "Updating..." : "Update"}
        </Button>
      </Banner>
    </Positioner>
  )
}

UpdateBanner.propTypes = {
  onClickReload: PropTypes.func,
}

export default UpdateBanner
