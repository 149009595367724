import styled from "styled-components";

export const Link = styled.a`
  font-size: 1.5em;
  color: inherit;
  text-decoration: none;
  position: relative;

  ::after {
    content: "";
    border-bottom: 0.08em solid currentColor;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0.1em;
    left: 0;
    transition: 0.2s;
  }

  :hover::after {
    border-bottom: 0.08em solid hsl(13deg 91% 73%);
  }
`;
