import p1 from "./1.webp";
import p2 from "./2.webp";
import v4 from "./4.webm";
import v5 from "./5.webm";
import v4Alt from "./4.mp4";
import v5Alt from "./5.mp4";
import p6 from "./6.webp";
import p7 from "./7.webp";
import v8 from "./8.webm";
import v8Alt from "./8.mp4";
import p9 from "./9.webp";
import v10 from "./10.webm";
import v11 from "./11.webm";
import v10Alt from "./10.mp4";
import v11Alt from "./11.mp4";

export const commute = {
  name: "Commute",
  meta: "Late 2017 - Mid 2018",
  desc: "An interactive visualisation tool to explore spatio-temporal transport datasets.\nCreated a custom force-directed chart that represents route choices and a time manager that makes filtering and navigating the temporal aspects of the dataset easy.\nBuilt using React, Redux, D3.js, Mapbox, Koa, PostGIS.",
  link: "https://github.com/sebastianquek/commute",
  pictures: [
    {
      src: p1,
      caption: "Dashboard screen",
      marginTop: 100,
      marginTopAlt: 40,
      gridColumn: "1 / span 3",
      gridColumnAlt: "1 / span 2",
      heightWidthPercentage: 50.5,
    },
    {
      src: p2,
      caption: "Basic shapes and colours used",
      marginTop: 80,
      gridColumn: "1 / span 1",
      heightWidthPercentage: 82.5,
    },
    {
      vidSrc: v4,
      vidSrcType: "video/webm",
      vidSrcAlt: v4Alt,
      vidSrcAltType: "video/mp4",
      caption: "Tooltips",
      marginTop: 180,
      gridColumn: "2 / span 2",
      gridColumnAlt: "2 / span 1",
      autoPlay: true,
      heightWidthPercentage: 75.6,
    },
    {
      vidSrc: v5,
      vidSrcType: "video/webm",
      vidSrcAlt: v5Alt,
      vidSrcAltType: "video/mp4",
      caption:
        "Graph to show route choices (1). Thickness and length of links are proportional to the number of commuters and duration of the route, respectively.",
      marginTop: 80,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / span 2",
      autoPlay: true,
      heightWidthPercentage: 78.6,
    },
    {
      src: p6,
      caption: "Graph to show route choices (2)",
      marginTop: 80,
      marginTopAlt: 200,
      gridColumn: "1 / span 2",
      gridColumnAlt: "1 / span 1",
      heightWidthPercentage: 117.4,
    },
    {
      src: p7,
      caption: "Graph to show route choices (3)",
      marginTop: 80,
      gridColumn: "2 / span 2",
      gridColumnAlt: "2 / span 1",
      heightWidthPercentage: 104.6,
    },
    {
      vidSrc: v8,
      vidSrcType: "video/webm",
      vidSrcAlt: v8Alt,
      vidSrcAltType: "video/mp4",
      caption: "Linking the map and chart",
      marginTop: 80,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / span 2",
      autoPlay: true,
      heightWidthPercentage: 60.3,
    },
    {
      src: p9,
      caption: "Example of land use composition",
      marginTop: 80,
      gridColumn: "1 / span 2",
      gridColumnAlt: "2 / span 1",
      heightWidthPercentage: 57.2,
    },
    {
      vidSrc: v10,
      vidSrcType: "video/webm",
      vidSrcAlt: v10Alt,
      vidSrcAltType: "video/mp4",
      caption: "Time manager for navigation",
      marginTop: 80,
      gridColumn: "1 / span 3",
      gridColumnAlt: "1 / span 2",
      autoPlay: true,
      heightWidthPercentage: 26.9,
    },
    {
      vidSrc: v11,
      vidSrcType: "video/webm",
      vidSrcAlt: v11Alt,
      vidSrcAltType: "video/mp4",
      caption: "Patterns of 7 similar neighbourhoods",
      marginTop: 40,
      gridColumn: "1 / -1",
      autoPlay: true,
      heightWidthPercentage: 26.9,
    },
  ],
  isMainProject: true,
};
