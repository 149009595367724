import React, { Component } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'

export class ScrollWrapper extends Component {
  constructor (props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleScroll = throttle(this.handleScroll, 100)
    this.state = {
      isAboveCurrentView: undefined
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  isVisible (elem) {
    return elem.getBoundingClientRect().top <= window.innerHeight && // check if top started to be in screen
      elem.getBoundingClientRect().bottom >= 0 // check if bottom is still within screen
  }

  isAboveCurrentViewport (elem) {
    return elem.getBoundingClientRect().bottom <= 0
  }

  isBelowCurrentViewport (elem) {
    return elem.getBoundingClientRect().top >= window.innerHeight
  }

  handleScroll (event) {
    this.setState(s => {
      const newState = {}
      const isVisible = this.isVisible(this.anchor)

      if (isVisible) {
        this.props.onScrollIntoView()
        newState.isAboveCurrentView = undefined
      } else {
        if (
          this.isAboveCurrentViewport(this.anchor) &&
          (s.isAboveCurrentView === undefined || !s.isAboveCurrentView)
        ) {
          this.props.onScrollOutOfViewToTop()
          newState.isAboveCurrentView = true
        } else if (
          this.isBelowCurrentViewport(this.anchor) &&
          (s.isAboveCurrentView === undefined || s.isAboveCurrentView)
        ) {
          this.props.onScrollOutOfViewToBottom()
          newState.isAboveCurrentView = false
        }
      }
      return newState
    })
  }

  render () {
    return (
      <div ref={(e) => (this.anchor = e)}>
        {this.props.children}
      </div>
    )
  }
}

ScrollWrapper.propTypes = {
  directionDetectionThreshold: PropTypes.number,
  onScrollIntoView: PropTypes.func,
  onScrollOutOfViewToBottom: PropTypes.func,
  onScrollOutOfViewToTop: PropTypes.func,
  children: PropTypes.node
}

ScrollWrapper.defaultProps = {
  directionDetectionThreshold: 600
}

export default ScrollWrapper
