import React from 'react'
import PropTypes from 'prop-types'
import ProjectDetails from './ProjectDetails'
import ProjectMedia from './ProjectMedia'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 30% auto 58% auto;
  grid-template-areas: ". description . pictures .";

  @media (max-width: 1000px) {
    grid-template-columns: 3% auto 3%;
    grid-template-areas: ". description ."
                         ". pictures .";
  }
}
`

const Project = (props) => {
  const detailsProps = (({ name, meta, desc, link }) => ({ name, meta, desc, link }))(props.project)
  const picturesProps = (({ pictures }) => ({ pictures }))(props.project)
  return (
    <Wrapper>
      <ProjectDetails {...detailsProps} />
      <ProjectMedia {...picturesProps} />
    </Wrapper>
  )
}

Project.propTypes = {
  project: PropTypes.object
}

export default Project
