import mainMenu from "./main-menu.webm";
import mainMenuAlt from "./main-menu.mp4";
import p2 from "./2.webp";
import p3 from "./3.webp";
import p4 from "./4.webp";
import playerMoves from "./player-moves.webp";
import ducking from "./ducking.webm";
import running from "./running.webm";
import jumping from "./jumping.webm";
import background from "./background.webp";
import icon from "./icon.png";

export const courir = {
  name: "Courir",
  meta: "Early 2016",
  desc: "A multiplayer endless-running iOS game where players compete against each other in real-time.\nPlay against your friends in a real-time test of agility, speed and concentration or generate obstacles to try to wipe your friends out in special multiplayer mode.\nBuilt using Swift, Cinema4D.",
  link: "https://github.com/LesCoureurs/Courir",
  pictures: [
    {
      vidSrc: mainMenu,
      vidSrcType: "video/webm",
      vidSrcAlt: mainMenuAlt,
      vidSrcAltType: "video/mp4",
      caption: "Main game screen",
      marginTop: 80,
      gridColumn: "1 / span 2",
      autoPlay: true,
      heightWidthPercentage: 66.7,
    },
    {
      src: p2,
      caption: "Single player mode",
      marginTop: 80,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / span 2",
      heightWidthPercentage: 75,
    },
    {
      src: p3,
      caption: "Duck under cranes",
      marginTop: 20,
      gridColumn: "1 / span 2",
      heightWidthPercentage: 75,
    },
    {
      src: p4,
      caption: "Jump over crates",
      marginTop: 20,
      gridColumn: "1 / span 2",
      heightWidthPercentage: 75,
    },
    {
      src: playerMoves,
      caption: "Player movements",
      marginTop: 20,
      gridColumn: "2 / span 2",
      gridColumnAlt: "1 / span 2",
      heightWidthPercentage: 75,
    },
    {
      vidSrc: ducking,
      caption: "ducking",
      marginTop: 20,
      autoPlay: true,
      noShadow: true,
      heightWidthPercentage: 75,
    },
    {
      vidSrc: running,
      caption: "running",
      marginTop: 300,
      autoPlay: true,
      noShadow: true,
      heightWidthPercentage: 75,
    },
    {
      vidSrc: jumping,
      caption: "jumping",
      marginTop: 150,
      marginTopAlt: -150,
      autoPlay: true,
      noShadow: true,
      heightWidthPercentage: 75,
    },
    {
      src: background,
      caption: "Environment",
      marginTop: 20,
      gridColumn: "1 / span 2",
      noShadow: true,
      heightWidthPercentage: 75,
    },
    {
      src: icon,
      caption: "Icon",
      marginTop: 20,
      heightWidthPercentage: 100,
      width: 152,
    },
    {
      vidSrc: "projects/courir/promo.mp4", // Too large to be cached, added to public dir directly
      caption: "Promo",
      marginTop: 20,
      gridColumn: "2 / -1",
      gridColumnAlt: "1 / -1",
      controls: true,
      heightWidthPercentage: 66.7,
    },
  ],
  isMainProject: true,
}