import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  padding-bottom: ${({heightWidthPercentage}) => heightWidthPercentage}%;
  position: relative;
  height: 100%;
  width: 100%;
  ${({loaded}) => !loaded && css`
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239f9f9f' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  `}
`

const Picture = styled.img`
  width: 100%;
  position: absolute;
  opacity: ${({loaded}) => loaded ? 1 : 0};
  transition: 0.8s;
  ${({noShadow}) => !noShadow && css`
    box-shadow: 0px 1px 10px 2px rgba(0,0,0,.2);
  `}
`

const Video = Picture.withComponent('video')

class MediaWrapper extends React.Component {
  constructor (props) {
    super(props)
    this.state = { loaded: false }
    this.mediaHasLoaded = this.mediaHasLoaded.bind(this)
    this.handleIntersect = this.handleIntersect.bind(this)
  }

  componentDidMount () {
    // Workaround as muted attribute doesn't work out as a React attribute
    // https://github.com/facebook/react/issues/10389
    if (this.videoRef) {
      this.videoRef.setAttribute('muted', '')

      this.observer = new IntersectionObserver(this.handleIntersect);
      this.observer.observe(this.videoRef);
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleIntersect (entries, observer) {
    entries.forEach(function(video) {
      if (video.isIntersecting) {
        for (const source in video.target.children) {
          const videoSource = video.target.children[source];
          if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            videoSource.src = videoSource.dataset.src;
          }
        }

        video.target.load();
        observer.unobserve(video.target);
      }
    });
  }

  mediaHasLoaded () {
    this.setState({ loaded: true })
  }

  render () {
    const mediaElem = this.props.src ? (
      <Picture
        {...this.state}
        {...this.props}
        src={this.props.src}
        alt={this.props.caption}
        onLoad={this.mediaHasLoaded}
        loading="lazy"
      />
    ) : (
      <Video
        {...this.state}
        {...this.props}
        ref={(r) => (this.videoRef = r)}
        loop={true}
        muted={true}
        alt={this.props.caption}
        onLoadedData={this.mediaHasLoaded}
        playsInline={true}
        crossOrigin="anonymous"
      >
        <source
          data-src={this.props.vidSrc}
          type={this.props.vidSrcType}
        />
        {this.props.vidSrcAlt && (
          <source
            data-src={this.props.vidSrcAlt}
            type={this.props.vidSrcAltType}
          />
        )}
      </Video>
    )

    return (
      <Wrapper
        heightWidthPercentage={this.props.heightWidthPercentage}
        loaded={this.state.loaded}
      >
        {mediaElem}
      </Wrapper>
    )
  }
}

MediaWrapper.propTypes = {
  src: PropTypes.string,
  vidSrc: PropTypes.string,
  vidSrcType: PropTypes.string,
  vidSrcAlt: PropTypes.string,
  vidSrcAltType: PropTypes.string,
  caption: PropTypes.string,
  heightWidthPercentage: PropTypes.number
}

export default MediaWrapper
