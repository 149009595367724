import p1 from "./p1.webp"
import v1 from "./v1.webm"
import v1Alt from "./v1.mp4"
import v2 from "./v2.webm"
import v2Alt from "./v2.mp4"
import p2 from "./p2.webp"
import p3 from "./p3.webp"
import v3 from "./v3.webm"
import v3Alt from "./v3.mp4"
import v4 from "./v4.webm"
import v4Alt from "./v4.mp4"

export const gantries = {
  name: "Gantries",
  meta: "2022",
  desc: "A map-centric approach to explore Singapore’s Electronic Road Pricing (ERP) rates.\nIncludes animations that aid in the discovery of gantry rates. Relies on automated GitHub Actions pipelines to periodically fetch, process and update the custom Mapbox style.\nBuilt using TypeScript, React, Mapbox, Playwright.",
  link: "https://gantries.info",
  pictures: [
    {
      src: p1,
      caption: "Map of all ERP gantries and current rates",
      marginTop: 200,
      marginTopAlt: 40,
      gridColumn: "1 / -1",
      heightWidthPercentage: 62.5,
    },
    {
      vidSrc: v1,
      vidSrcType: "video/webm",
      vidSrcAlt: v1Alt,
      vidSrcAltType: "video/mp4",
      caption: "Animation to hint of a swipe gesture",
      marginTop: 80,
      autoPlay: true,
      heightWidthPercentage: 200,
    },
    {
      vidSrc: v2,
      vidSrcType: "video/webm",
      vidSrcAlt: v2Alt,
      vidSrcAltType: "video/mp4",
      caption: "Swipe gesture to see all rates",
      marginTop: 240,
      autoPlay: true,
      heightWidthPercentage: 200,
    },
    {
      src: p2,
      caption: "Collapsed view showing upcoming rates",
      marginTop: 80,
      marginTopAlt: 120,
      gridColumn: "2 / span 1",
      gridColumnAlt: "1 / span 1",
      heightWidthPercentage: 185.2,
    },
    {
      src: p3,
      caption: "Expanded view showing all rates",
      marginTop: -240,
      marginTopAlt: 40,
      heightWidthPercentage: 185.2,
    },
    {
      vidSrc: v3,
      vidSrcType: "video/webm",
      vidSrcAlt: v3Alt,
      vidSrcAltType: "video/mp4",
      caption: "See rates by vehicle type",
      marginTop: -160,
      marginTopAlt: 80,
      autoPlay: true,
      heightWidthPercentage: 201.4,
    },
    {
      vidSrc: v4,
      vidSrcType: "video/webm",
      vidSrcAlt: v4Alt,
      vidSrcAltType: "video/mp4",
      caption: "See rates by time",
      marginTop: 160,
      marginTopAlt: 80,
      autoPlay: true,
      heightWidthPercentage: 201.4,
    },
  ],
  isMainProject: true,
}