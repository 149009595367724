import styled from "styled-components";
import { Link } from "./Link";

const ProjectLinkComponent = styled(Link)`
  margin-top: 1.2em;
  display: inline-flex;
  align-items: center;
  gap: 0.3em;

  svg {
    position: relative;
    top: 0.1em;
  }

  ::after {
    border-bottom: 0.08em solid var(--text-secondary);
    opacity: 70%;
  }

  :hover::after {
    opacity: 100%;
  }
`;

export const ProjectLink = ({ title, url }) => (
  <ProjectLinkComponent href={url} alt={title} target="_blank">
    Explore
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
      width="0.8em"
      height="0.8em"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
      />
    </svg>
  </ProjectLinkComponent>
);
