import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ScrollWrapper from './ScrollWrapper'
import MediaWrapper from './MediaWrapper'

const MediaFrame = styled.div`
  width: 100%;
  max-width: 100%;
  ${({width}) => width && css`max-width: ${width}px;`}

  justify-self: ${({justifySelf}) => justifySelf || 'center'};
  
  ${({marginTop}) => marginTop && css`margin-top: ${marginTop}px;`}
  ${({gridColumn}) => gridColumn && css`grid-column: ${gridColumn};`}
  ${({gridRow}) => gridRow && css`grid-row: ${gridRow};`}
  
  @media (max-width: 1200px) {
    ${({marginTopAlt}) => marginTopAlt && css`
      margin-top: ${marginTopAlt}px;
    `}
    ${({gridColumnAlt}) => gridColumnAlt && css`
      grid-column: ${gridColumnAlt};
    `}
  }

  @media (max-width: 1000px) {
    ${({marginTop}) => marginTop && css`margin-top: ${marginTop}px;`}
    ${({gridColumn}) => gridColumn && css`grid-column: ${gridColumn};`}
  }

  @media (max-width: 700px) {
    ${({marginTopAlt}) => marginTopAlt && css`
      margin-top: ${marginTopAlt}px;
    `}
    ${({gridColumnAlt}) => gridColumnAlt && css`
      grid-column: ${gridColumnAlt};
    `}
  }
`

const Caption = styled.p`
  color: var(--text-secondary);
  font-family: 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  margin-bottom: 0.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.7em;
`

class ProjectMediaItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      duration: this.generateDuration(),
      yOffset: this.generateYOffset()
    }
    this.setupAnimationFromBottom = this.setupAnimationFromBottom.bind(this)
    this.setupAnimationFromTop = this.setupAnimationFromTop.bind(this)
    this.startAnimation = this.startAnimation.bind(this)
  }

  generateYOffset () {
    return this.getRandomArbitrary(3, 7)
  }

  generateDuration () {
    return this.getRandomArbitrary(0.6, 1.2)
  }

  getRandomArbitrary (min, max) {
    return (Math.random() * (max - min) + min).toFixed(2)
  }

  setupAnimationFromBottom () {
    this.setState({
      duration: this.generateDuration(),
      yOffset: this.generateYOffset()
    })
  }

  setupAnimationFromTop () {
    this.setState({
      duration: this.generateDuration(),
      yOffset: -this.generateYOffset()
    })
  }

  startAnimation () {
    this.setState({
      yOffset: 0
    })
  }

  render () {
    return (
      <MediaFrame
        {...this.props}
        style={{
          transform: `translate3d(0, ${this.state.yOffset}em, 0)`,
          transition: `${this.state.duration}s`,
        }}
      >
        <ScrollWrapper
          onScrollIntoView={this.startAnimation}
          onScrollOutOfViewToBottom={this.setupAnimationFromBottom}
          onScrollOutOfViewToTop={this.setupAnimationFromTop}>
          <Caption>{this.props.caption}</Caption>
          <MediaWrapper {...this.props}/>
        </ScrollWrapper>
      </MediaFrame>
    )
  }
}

ProjectMediaItem.propTypes = {
  caption: PropTypes.string,
  src: PropTypes.string,
  vidSrc: PropTypes.string
}

export default ProjectMediaItem
